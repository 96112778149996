import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Usuario from '../usuario/usuario';
import Cliente from '../cliente/cliente';
import Fornecedor from '../fornecedor/fornecedor';
import CentroDistribuicao from '../centroDistribuicao/centroDistribuicao';
import Produto from '../produto/produto';
import Viagem from '../viagem/viagem';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/cliente' component={Cliente} />
			<Route path='/centroDistribuicao' component={CentroDistribuicao} />
			<Route path='/fornecedor' component={Fornecedor} />
			<Route path='/produto' component={Produto} />
			<Route path='/viagem' component={Viagem} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
