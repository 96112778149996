import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../../auth/authActions';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false };
	}
	changeOpen() {
		this.setState({ open: !this.state.open });
	}
	render() {
		const { nome, login } = this.props.usuario;

		return (
			<ul className="navbar-nav ml-auto">
				<li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="far fa-user"></i>
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
						<span className="dropdown-item dropdown-header">Perfil</span>
						<div className="dropdown-divider"></div>
						<a href="#" className="dropdown-item">
							<i className="fas fa-user mr-2"></i> Nome
							<span className="float-right text-muted text-sm">{nome}</span>
						</a>
					<div className="dropdown-divider"></div>
					<a href="#" className="dropdown-item">
						<i className="fas fa-envelope mr-2"></i> Usuário
						<span className="float-right text-muted text-sm">{login}</span>
					</a>
					<div className="dropdown-divider"></div>
						<a href="#" onClick={this.props.logout}
							className="btn btn-block btn-primary btn-sm">Sair</a>
					</div>
				</li>
			</ul>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario
});
const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
