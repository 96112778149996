import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './viagemForm';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	setModoTela, setFiltro, initForm, salvar, excluir, getLista, finalizar, reabrir,
	getListaCliente, getListaFornecedor, getListaPropriedade, getListaEventoTipo, getListaProduto, getListaCentroDistribuicao
} from './viagemActions';


class Viagem extends Component {

	state = {
		pesquisar: '',
		pesquisarAux: '',
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaCliente();
		this.props.getListaFornecedor();
		this.props.getListaPropriedade();
		this.props.getListaEventoTipo();
		this.props.getListaProduto();
		this.props.getListaCentroDistribuicao();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'visualizacao' ? (
						<Form onSubmit={() => {}} visualizacao />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = this.props.lista.filter(item => {

			if (this.state.pesquisar
				&& !(`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_carregamento)} ${item.solicitacao || ''}
				${item.nota_fiscal_paletes || ''} ${item.nota_fiscal_produtos || ''} ${item.nome_cliente_origem}
				${item.nome_cliente_destino} ${item.nome_propriedade} ${item.nome_produto || ''} ${item.quantidade} ${item.cavalo || ''}
				${item.carreta || ''} ${item.motorista || ''} ${item.redespacho ? 'Sim' : 'Não'} ${item.datahora_finalizacao ? 'Finalizada' : 'Aberta'}
				${item.cte_origem || ''} ${item.ultimo_evento}
				`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		})

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {
										datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
										baixa_piso: true
									});
									this.props.initForm({
										datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
										baixa_piso: false
									});
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Viagem'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carregamento),
									quantidade: parseInt(item.quantidade || 0),
									nota_fiscal: `${item.nota_fiscal_paletes || ''}${item.nota_fiscal_paletes && item.nota_fiscal_produtos ? ' / ' : ''}${item.nota_fiscal_produtos || ''}`,
									redespacho: item.redespacho ? 'Sim' : 'Não',
									status: item.datahora_finalizacao ? 'Finalizada' : 'Aberta'
								}))} name='Viagem'>
									<ExcelColumn label='Data/Hora' value='datahora_carregamento' />
									<ExcelColumn label='Solicitação' value='solicitacao' />
									<ExcelColumn label='CT-e Origem' value='cte_origem' />
									<ExcelColumn label='NF Palete/Produto' value='nota_fiscal' />
									<ExcelColumn label='Cliente Origem' value='nome_cliente_origem' />
									<ExcelColumn label='Cliente Destino' value='nome_cliente_destino' />
									<ExcelColumn label='Propriedade' value='nome_propriedade' />
									<ExcelColumn label='Produto' value='nome_produto' />
									<ExcelColumn label='Quantidade' value='quantidade' />
									<ExcelColumn label='Cavalo' value='cavalo' />
									<ExcelColumn label='Carreta' value='carreta' />
									<ExcelColumn label='Motorista' value='motorista' />
									<ExcelColumn label='Redespacho' value='redespacho' />
									<ExcelColumn label='Status' value='status' />
									<ExcelColumn label='Evento' value='ultimo_evento' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
									this.setState({ ...this.state, pesquisar: this.state.pesquisarAux });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
							}} />

						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
							}} />
					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisarAux}
						 onChange={e => {
							this.setState({ ...this.state, pesquisarAux: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter width={100}>Data/Hora</Th>
								<Th alignCenter width={20}>Sol.</Th>
								<Th alignCenter width={20}>CT-e Origem</Th>
								<Th alignCenter width={100}>NF Palete/Produto</Th>
								<Th alignCenter width={100}>Cliente Origem</Th>
								<Th alignCenter width={100}>Cliente Destino</Th>
								<Th alignCenter width={20}>Prop.</Th>
								<Th alignCenter width={20}>Produto</Th>
								<Th alignCenter width={20}>Qtd.</Th>
								<Th alignCenter width={20}>Cavalo</Th>
								<Th alignCenter width={20}>Carreta</Th>
								<Th alignCenter width={100}>Motorista</Th>
								<Th alignCenter width={20}>Redesp.</Th>
								<Th alignCenter width={20}>Status</Th>
								<Th alignCenter width={100}>Evento</Th>
								<Th alignCenter width={100}></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map((item, i) => (
								<Tr key={i} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter minWidth={100} small>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_carregamento)}</Td>
									<Td alignCenter minWidth={20} small>{item.solicitacao || ''}</Td>
									<Td alignCenter minWidth={20} noWrap small>{item.cte_origem || ''}</Td>
									<Td alignCenter minWidth={100} noWrap small>{item.nota_fiscal_paletes || ''}{item.nota_fiscal_paletes && item.nota_fiscal_produtos ? ' / ' : ''}{item.nota_fiscal_produtos || ''}</Td>
									<Td alignCenter minWidth={100} noWrap small>{item.nome_cliente_origem}</Td>
									<Td alignCenter minWidth={100} noWrap small>{item.nome_cliente_destino}</Td>
									<Td alignCenter minWidth={20} noWrap small>{item.nome_propriedade}</Td>
									<Td alignCenter minWidth={20} noWrap small>{item.nome_produto || ''}</Td>
									<Td alignCenter minWidth={20} small>{item.quantidade}</Td>
									<Td alignCenter minWidth={20} small>{item.cavalo || ''}</Td>
									<Td alignCenter minWidth={20} small>{item.carreta || ''}</Td>
									<Td alignCenter minWidth={100} noWrap small>{item.motorista || ''}</Td>
									<Td alignCenter minWidth={20} small>{item.redespacho ? 'Sim' : 'Não'}</Td>
									<Td alignCenter minWidth={20} small>{item.datahora_finalizacao ? 'Finalizada' : 'Aberta'}</Td>
									<Td alignCenter minWidth={100} noWrap small>{item.ultimo_evento}</Td>
									<Td alignCenter minWidth={100} small>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-eye'}
											visible={this.state.linhaSelecionada == item.id}
											title='Visualizar'
											small
											event={() => {
												this.props.setModoTela('visualizacao', {
													...item,
													datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carregamento)
												});
												this.props.initForm({
													...item,
													datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carregamento)
												});
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											disabled={!item.ativo}
											visible={this.state.linhaSelecionada == item.id}
											title='Editar'
											small
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carregamento)
												});
												this.props.initForm({
													...item,
													datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_carregamento)
												});
											}} />

										<ButtonTable
											type={'success'}
											icon={`fas fa-${!item.datahora_finalizacao ? 'check' : 'undo'}`}
											visible={this.state.linhaSelecionada == item.id}
											disabled={false}
											title={!item.datahora_finalizacao ? 'Finalizar viagem' : 'Reabrir viagem'}
											small
											event={() => {
												if (!item.datahora_finalizacao) {
													this.props.finalizar(item);
												} else {
													this.props.reabrir(item);
												}
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.viagem.aguardando,
	filtro: state.viagem.filtro,
	modoTela: state.viagem.modoTela,
	filtro: state.viagem.filtro,
	lista: state.viagem.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, initForm, salvar, excluir, getLista, finalizar, reabrir,
	getListaCliente, getListaFornecedor, getListaPropriedade, getListaEventoTipo, getListaProduto, getListaCentroDistribuicao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Viagem);
