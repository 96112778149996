import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import imagemUser from '../assets/images/user.jpg';

import { getLista, getListaCliente, setFiltro } from './dashboardActions';

class Dashboard extends Component {

	state = {
		pesquisar: '',
		cliente: true,
		fornecedor: true,
		status_verde: true,
		status_vermelho: true,
		id_fornecedor_selecionado: null,
		nome_fornecedor_selecionado: ''
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {

		this.props.getLista();
		this.props.getListaCliente();
		setInterval(() => {
			this.props.getLista();
		}, 60000);
    }

    render() {

		let agrupador = null;
		let total = 0;

        return (
			<Content>

				<Aguardando aguardando={this.props.aguardando} />

				<ContentCard>
					<ContentCardHeader>
						<Row>
							<LabelAndInputMask
								label='Data/Hora' placeholder='Informe o período inicial'
								cols='12 6 3 2'
								mask='99/99/9999'
								value={this.props.filtro.data_inicial}
								onChange={(data) => {
									this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
								}} />

							<LabelAndInputMask
								label='Data/Hora' placeholder='Informe o período final'
								cols='12 6 3 2'
								mask='99/99/9999'
								value={this.props.filtro.data_final}
								onChange={(data) => {
									this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
								}} />
							
							<Select
								options={this.props.listaCliente}
								label='Cliente de Origem'
								cols='12 8 4 6'
								placeholder='Selecione o cliente'
								value={this.props.filtro.id_cliente}
								onChange={value => {
									this.props.setFiltro({ ...this.props.filtro, id_cliente: value });
								}} />

							<Grid cols='12 4 2 2' style={{ paddingTop: 28 }}>
								<Button
									text='Atualizar'
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.getLista();
									}} />
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<TBody>
								{this.props.lista.map((item, i) => {
									
									let novoAgrupador = false;
									if (!agrupador || `${item.nome_produto} / ${item.nome_propriedade}` != agrupador) {
										agrupador = `${item.nome_produto} / ${item.nome_propriedade}`;
										novoAgrupador = true;
										total = 0;
									}
									total += parseInt(item.quantidade);

									let mostrarTotal = !this.props.lista[i+1] || `${this.props.lista[i+1].nome_produto} / ${this.props.lista[i+1].nome_propriedade}` != agrupador;

									return (
										<>
											{novoAgrupador ? (
												<Tr>
													<Th colspan={2}>{agrupador}</Th>
												</Tr>
											) : null}
											
											<Tr>
												<Td>{item.nome_evento}</Td>
												<Td alignCenter>{parseInt(item.quantidade)}</Td>
											</Tr>

											{mostrarTotal ? (
												<Tr>
													<Td>Total</Td>
													<Td alignCenter>{parseInt(total)}</Td>
												</Tr>
											) : null}
										</>										
									);
								})}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			</Content>
        )
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.dashboard.aguardando,
	lista: state.dashboard.lista,
	listaCliente: state.dashboard.listaCliente,
	filtro: state.dashboard.filtro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getLista, getListaCliente, setFiltro
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
