import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	aguardando: false,
	lista: [],
	listaCliente: [],
	filtro: {
		id_cliente: null,
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

		case 'DASHBOARD_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

		case 'DASHBOARD_FILTRO':
            return { ...state,
				filtro: action.payload.filtro
			};

		case 'DASHBOARD_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'DASHBOARD_CLIENTE_SELECT_LISTADO':
            return {
				...state,
				listaCliente: action.payload.data
			};

        default:
            return state;
    }
}
