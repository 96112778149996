import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';

class Menu extends Component {

	constructor(props) {
		super(props);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		// if (this.props.usuario && this.props.usuario.modoCliente && (
		// 	selecionado == '/usuario' || selecionado == '/cliente' || selecionado == '/transportadora' || selecionado == '/embalagem'
		// 	|| selecionado == '/fornecedor'	|| selecionado == '/fornecedorEmbalagem' || selecionado == '/embalagemEntrada'
		// 	|| selecionado == '/embalagemSaidaMotivo' || selecionado == '/embalagemSaida' || selecionado == '/embalagemViagem'
		// 	|| selecionado == '/embalagemInventario' || selecionado == '/embalagemViagemComprovante' || selecionado == '/inventario')) {
		//
		// 	window.location = '/';
		// }
		// if (this.props.usuario && this.props.usuario.modoTransportadora && (
		// 	selecionado == '/usuario' || selecionado == '/cliente' || selecionado == '/transportadora' || selecionado == '/embalagem'
		// 	|| selecionado == '/fornecedor'	|| selecionado == '/fornecedorEmbalagem' || selecionado == '/embalagemEntrada'
		// 	|| selecionado == '/embalagemSaidaMotivo' || selecionado == '/embalagemSaida'
		// 	|| selecionado == '/embalagemInventario' || selecionado == '/acumulado' || selecionado == '/inventario')) {
		//
		// 	window.location = '/';
		// }
		//
		// if (this.props.usuario && this.props.usuario.modoFornecedor && (
		// 	selecionado == '/' || selecionado == '/usuario' || selecionado == '/cliente' || selecionado == '/transportadora'
		// 	|| selecionado == '/embalagem'	|| selecionado == '/embalagemConsulta' || selecionado == '/fornecedor'
		// 	|| selecionado == '/fornecedorConsulta' || selecionado == '/fornecedorEmbalagem' || selecionado == '/embalagemEntrada'
		// 	|| selecionado == '/embalagemSaidaMotivo' || selecionado == '/embalagemSaida' || selecionado == '/embalagemViagem'
		// 	|| selecionado == '/embalagemViagemComprovante'
		// 	|| selecionado == '/acumulado' || selecionado == '/embalagemInventario')) {
		//
		// 	window.location = '/#/inventario';
		// }

		return (
			<nav className="mt-2">
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
					<MenuItem path='/' label='Dashboard' icon='fas fa-tachometer-alt' active={selecionado == '/'} />
					<MenuItem path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />
					<MenuItem path='cliente' label='Cliente' icon='fas fa-user-circle' active={selecionado == '/cliente'} />
					<MenuItem path='fornecedor' label='Fornecedor' icon='fas fa-user-plus' active={selecionado == '/fornecedor'} />
					<MenuItem path='centroDistribuicao' label='Centro de Distribuição' icon='fas fa-truck-loading' active={selecionado == '/centroDistribuicao'} />
					<MenuItem path='produto' label='Produto' icon='fas fa-pallet' active={selecionado == '/produto'} />
					<MenuItem path='viagem' label='Viagem' icon='fas fa-truck' active={selecionado == '/viagem'} />
				</ul>
			</nav>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
