import React from 'react';
import Menu from './menu';
import imagemLogo from '../../assets/images/sologo.png';

export default props => (
	<aside className="main-sidebar elevation-4 sidebar-light-indigo">
		<a href="../../" className="brand-link navbar-light">
			<img src={imagemLogo} alt="Letsara Logo" className="brand-image" style={{ marginTop: -7 }} />
			<div style={{ display: 'flex', flexDirection: 'column', marginTop: -10 }}>
				<span className="brand-text font-weight-light"><b>Letsara</b></span>
				<span className="brand-text font-weight-light" style={{ marginTop: -4, fontSize: 12 }}>Gestão de Paletes</span>
			</div>
		</a>
		<div className="sidebar">
			<Menu />
		</div>
	</aside>
)
