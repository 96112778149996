import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectDefault from '../common/form/selectDefault';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import FormEvento from './viagemEventoForm';
import { setError } from '../common/toast/toast';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm, finalizar, reabrir,
	setListaEvento, getListaEventos, setModoTelaEvento, initFormEvento, salvarEvento, excluirEvento,
	buscaInformacaoSolicitacao
} from './viagemActions';

class ViagemForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorSolicitacaoAnexo = document.createElement('input');
		fileSelectorSolicitacaoAnexo.setAttribute('type', 'file');
		fileSelectorSolicitacaoAnexo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.solicitacao_anexo || []).map(item => item);
					lista.push({
						solicitacao_anexo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						solicitacao_anexo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorSolicitacaoAnexo = fileSelectorSolicitacaoAnexo;

		const fileSelectorCteOrigemAnexo = document.createElement('input');
		fileSelectorCteOrigemAnexo.setAttribute('type', 'file');
		fileSelectorCteOrigemAnexo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.cte_origem_anexo || []).map(item => item);
					lista.push({
						cte_origem_anexo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						cte_origem_anexo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorCteOrigemAnexo = fileSelectorCteOrigemAnexo;

		const fileSelectorNotaFiscalAnexo = document.createElement('input');
		fileSelectorNotaFiscalAnexo.setAttribute('type', 'file');
		fileSelectorNotaFiscalAnexo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.nota_fiscal_paletes_anexo || []).map(item => item);
					lista.push({
						nota_fiscal_paletes_anexo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						nota_fiscal_paletes_anexo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorNotaFiscalAnexo = fileSelectorNotaFiscalAnexo;

		const fileSelectorNotaFiscalProdutoAnexo = document.createElement('input');
		fileSelectorNotaFiscalProdutoAnexo.setAttribute('type', 'file');
		fileSelectorNotaFiscalProdutoAnexo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.nota_fiscal_produtos_anexo || []).map(item => item);
					lista.push({
						nota_fiscal_produtos_anexo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						nota_fiscal_produtos_anexo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorNotaFiscalProdutoAnexo = fileSelectorNotaFiscalProdutoAnexo;

		const fileSelectorValePaleteAnexo = document.createElement('input');
		fileSelectorValePaleteAnexo.setAttribute('type', 'file');
		fileSelectorValePaleteAnexo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.vale_palete_anexo || []).map(item => item);
					lista.push({
						vale_palete_anexo_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						vale_palete_anexo: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorValePaleteAnexo = fileSelectorValePaleteAnexo;

		this.props.getListaEventos(this.props.formularioValues.id || 0);

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir || this.props.visualizacao || (this.props.formularioValues && this.props.formularioValues.datahora_finalizacao) ? 'readOnly' : '';

        return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						{this.props.formularioValues.redespacho ? (
							<Row>
								<Grid cols='6 6 4 3'>
									<label>REDESPACHO</label>
								</Grid>
							</Row>
						) : null}
						{this.props.formularioValues.id && this.props.formularioValues.viagem_anterior ? (
							<Grid cols='6 6 4 3'>
								<Button
									text='Ver Viagem Anterior'
									type={'primary'}
									icon={'fa fa-check'}
									event={() => {
										this.props.setModoTela('visualizacao', {
											...this.props.formularioValues.viagem_anterior,
											datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.viagem_anterior.datahora_carregamento)
										});
										this.props.initForm({
											...this.props.formularioValues.viagem_anterior,
											datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.viagem_anterior.datahora_carregamento)
										});
										this.props.getListaEventos(this.props.formularioValues.viagem_anterior.id);
									}} />
							</Grid>
						) : null}
						{this.props.formularioValues.id && this.props.formularioValues.viagem_redespacho ? (
							<Grid cols='6 6 4 3'>
								<Button
									text='Ver Redespacho'
									type={'primary'}
									icon={'fa fa-check'}
									event={() => {
										this.props.setModoTela('visualizacao', {
											...this.props.formularioValues.viagem_redespacho,
											datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.viagem_redespacho.datahora_carregamento)
										});
										this.props.initForm({
											...this.props.formularioValues.viagem_redespacho,
											datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(this.props.formularioValues.viagem_redespacho.datahora_carregamento)
										});
										this.props.getListaEventos(this.props.formularioValues.viagem_redespacho.id);
									}} />
							</Grid>
						) : null}
					</ContentCardHeader>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='solicitacao'
									component={LabelAndInput}
									label='Solicitação' placeholder='Informe a solicitação'
									cols='12 6 4 4'
									readOnly={readOnly} />

								<Grid cols='6 6 4 3' style={{ marginTop: 29 }}>
									<Button
										text='Buscar Informações KMM'
										type={'primary'}
										icon={'fa fa-check'}
										disabled={this.props.visualizacao}
										event={() => {
											this.props.buscaInformacaoSolicitacao(this.props.formularioValues);
										}} />
								</Grid>
							</Row>
							<Row>
								<Field
									name='datahora_carregamento'
									component={LabelAndInputMask}
									label='Data/Hora Carregamento' placeholder='Informe a data e hora'
									cols='12 6 4 4'
									mask='99/99/9999 99:99'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='id_cliente_origem'
									component={SelectDefault}
									options={this.props.listaCliente}
									label='Cliente Origem'
									cols='12 6 6 6'
									placeholder='Selecione o cliente'
									readOnly={readOnly} />

								<Field
									name='id_cliente_destino'
									component={SelectDefault}
									options={this.props.listaCliente}
									label='Cliente Destino'
									cols='12 6 6 6'
									placeholder='Selecione o cliente'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='cte_origem'
									component={LabelAndInput}
									label='CT-e Origem' placeholder='Informe o CT-e'
									cols='12 6 4 4'
									readOnly={readOnly} />

								<Field
									name='nota_fiscal_paletes'
									component={LabelAndInput}
									label='Nota Fiscal de Palete' placeholder='Informe a nota fiscal'
									cols='12 6 4 4'
									readOnly={readOnly} />

								<Field
									name='nota_fiscal_produtos'
									component={LabelAndInput}
									label='Nota Fiscal de Produto' placeholder='Informe a nota fiscal'
									cols='12 6 4 4'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='motorista'
									component={LabelAndInput}
									label='Motorista' placeholder='Informe o motorista'
									cols='12 6 4 4'
									readOnly={readOnly} />

								<Field
									name='carreta'
									component={LabelAndInput}
									label='Carreta' placeholder='Informe a carreta'
									cols='12 6 4 4'
									readOnly={readOnly} />

								<Field
									name='cavalo'
									component={LabelAndInput}
									label='Cavalo' placeholder='Informe o cavalo'
									cols='12 6 4 4'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Select
									name='baixa_piso'
									options={[{ id: 1, valor: 'Sim' }, { id: 2, valor: 'Não' }]}
									label='Baixa em Piso'
									cols='12 6 6 6'
									placeholder='Selecione se baixou em piso'
									readOnly={readOnly}
									value={this.props.formularioValues.baixa_piso ? 1 : 2}
									onChange={data => {
										if (data) {
											this.props.initForm({
												...this.props.formularioValues,
												baixa_piso: data == 1
											});
										}
									}} />

								<Field
									name='id_centro_distribuicao'
									component={Select}
									options={this.props.listaCentroDistribuicao}
									label='Centro de Distribuição'
									cols='12 6 6 6'
									placeholder='Selecione o CD'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='id_produto'
									component={Select}
									options={this.props.listaProduto}
									label='Produto'
									cols='12 8 4 4'
									placeholder='Selecione o produto'
									readOnly={readOnly} />

								<Field
									name='quantidade'
									component={LabelAndInputMask}
									label='Quantidade' placeholder='Informe a quantidade'
									cols='12 4 4 3'
									casas={0}
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='id_propriedade'
									component={Select}
									options={this.props.listaPropriedade}
									label='Propriedade'
									cols='12 6 6 6'
									placeholder='Selecione a propriedade'
									readOnly={readOnly} />

								<Field
									name='id_fornecedor'
									component={Select}
									options={this.props.listaFornecedor}
									label='Fornecedor'
									cols='12 6 6 6'
									placeholder='Selecione o fornecedor'
									readOnly={readOnly} />
							</Row>

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Solicitação</label>
								</Grid>
								{(this.props.formularioValues.solicitacao_anexo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.solicitacao_anexo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.solicitacao_anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-paletes.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														solicitacao_anexo: this.props.formularioValues.solicitacao_anexo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorSolicitacaoAnexo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

							<br />

							<Row>
								<Grid cols='12 12 12 12'>
									<label>CT-e Origem</label>
								</Grid>
								{(this.props.formularioValues.cte_origem_anexo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.cte_origem_anexo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.cte_origem_anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-paletes.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														cte_origem_anexo: this.props.formularioValues.cte_origem_anexo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorCteOrigemAnexo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

							<br />

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Nota Fiscal de Palete</label>
								</Grid>
								{(this.props.formularioValues.nota_fiscal_paletes_anexo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.nota_fiscal_paletes_anexo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.nota_fiscal_paletes_anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-paletes.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														nota_fiscal_paletes_anexo: this.props.formularioValues.nota_fiscal_paletes_anexo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorNotaFiscalAnexo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

							<br />

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Nota Fiscal de Produto</label>
								</Grid>
								{(this.props.formularioValues.nota_fiscal_produtos_anexo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.nota_fiscal_produtos_anexo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.nota_fiscal_produtos_anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-paletes.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														nota_fiscal_produtos_anexo: this.props.formularioValues.nota_fiscal_produtos_anexo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorNotaFiscalProdutoAnexo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

							<br />

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Vale Palete / Canhoto de NF / Protocolo de Devolução</label>
								</Grid>
								{(this.props.formularioValues.vale_palete_anexo || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.vale_palete_anexo_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.vale_palete_anexo_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-paletes.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														vale_palete_anexo: this.props.formularioValues.vale_palete_anexo.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorValePaleteAnexo.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Cancelar'
											submit type={'danger'}
											icon={'fa fa-trash'}
											disabled={this.props.visualizacao} />
									) : (!this.props.formularioValues || !this.props.formularioValues.datahora_finalizacao ? (
										<Button
											text='Salvar'
											submit
											type={'success'}
											icon={'fa fa-check'}
											disabled={this.props.visualizacao} />
									) : null)}
								</Grid>
								{this.props.formularioValues.id && !this.props.formularioValues.redespacho ? (
									<Grid cols='6 6 4 3'>
										<Button
											text='Redespacho'
											type={'primary'}
											icon={'fa fa-check'}
											disabled={this.props.visualizacao}
											event={() => {
												this.props.setModoTela('cadastro', {
													...this.props.formularioValues,
													id: null,
													redespacho: true,
													id_viagem_redespacho: this.props.formularioValues.id
												});
												this.props.initForm({
													...this.props.formularioValues,
													id: null,
													redespacho: true,
													id_viagem_redespacho: this.props.formularioValues.id
												});
											}} />
									</Grid>
								) : null}
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>
				{this.props.formularioValues.id ?
					this.renderEventos(readOnly)
					: null
				}

			</div>
        )
    }

	renderEventos(readOnly) {

		if (this.props.modoTelaEvento == 'cadastro') {
			return (
				<FormEvento onSubmit={this.props.salvarEvento} />
			);
		} else if (this.props.modoTelaEvento == 'exclusao') {
			return (
				<FormEvento excluir onSubmit={this.props.excluirEvento} />
			);
		} else if (this.props.modoTelaEvento == 'lista') {

			return (

				<ContentCard>
					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 4 2'>
								{this.props.formularioValues && !this.props.formularioValues.datahora_finalizacao ? (
									<Button
										text='Adicionar Evento'
										type={'success'}
										icon={'fa fa-plus'}
										disabled={readOnly ? true : false}
										event={() => {
											this.props.setModoTelaEvento('cadastro', {
												id_viagem: this.props.formularioValues.id,
												cavalo: this.props.listaEventos[this.props.listaEventos.length -1] ? this.props.listaEventos[this.props.listaEventos.length -1].cavalo : this.props.formularioValues.cavalo,
												carretas: this.props.listaEventos[this.props.listaEventos.length -1] ? this.props.listaEventos[this.props.listaEventos.length -1].carretas : `${this.props.formularioValues.carreta1 || ''}${this.props.formularioValues.carreta2 ? ', ' : ''}${this.props.formularioValues.carreta2 || ''}`
											});
											this.props.initFormEvento({
												id_viagem: this.props.formularioValues.id,
												cavalo: this.props.listaEventos[this.props.listaEventos.length -1] ? this.props.listaEventos[this.props.listaEventos.length -1].cavalo : this.props.formularioValues.cavalo,
												carretas: this.props.listaEventos[this.props.listaEventos.length -1] ? this.props.listaEventos[this.props.listaEventos.length -1].carretas : `${this.props.formularioValues.carreta1 || ''}${this.props.formularioValues.carreta2 ? ', ' : ''}${this.props.formularioValues.carreta2 || ''}`
											});
										}} />
								) : null}
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Data/Hora</Th>
									<Th>Evento</Th>
									<Th>Observação</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaEventos.map(item => (
									<Tr key={item.id}>
										<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento)}</Td>
										<Td>{item.nome_evento}</Td>
										<Td>{item.observacao}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={true}
												disabled={readOnly ? true : false}
												event={() => {
													this.props.setModoTelaEvento('cadastro', {
														...item,
														datahora_evento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento)
													});
													this.props.initFormEvento({
														...item,
														datahora_evento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento)
													});
												}} />
											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true}
												disabled={readOnly ? true : false}
												event={() => {
													this.props.setModoTelaEvento('exclusao', {
														...item,
														datahora_evento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento)
													});
													this.props.initFormEvento({
														...item,
														datahora_evento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento)
													});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			);
		}
	}

}

ViagemForm = reduxForm({form: 'viagemForm', destroyOnUnmount: false})(ViagemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('viagemForm')(state),
	registro: state.viagem.registro,
	listaCliente: state.viagem.listaCliente,
	listaFornecedor: state.viagem.listaFornecedor,
	listaPropriedade: state.viagem.listaPropriedade,
	listaEventoTipo: state.viagem.listaEventoTipo,
	listaProduto: state.viagem.listaProduto,
	listaCentroDistribuicao: state.viagem.listaCentroDistribuicao,
	listaEventos: state.viagem.listaEventos,
	modoTelaEvento: state.viagem.modoTelaEvento
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, finalizar, reabrir,
	setListaEvento, getListaEventos, setModoTelaEvento, initFormEvento, salvarEvento, excluirEvento,
	buscaInformacaoSolicitacao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViagemForm);
