import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setAguardando(aguardando) {
    return {
        type: 'DASHBOARD_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro = {}) {
    return {
        type: 'DASHBOARD_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function getLista() {

	return (dispatch, getState) => {
		
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/viagem/listarDashboard?
			id_cliente=${getState().dashboard.filtro.id_cliente}
			&data_inicial=${DateFormat.formatarDataTelaParaSql(getState().dashboard.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboard.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().dashboard.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().dashboard.filtro.data_final)}
		`).then(resp => {
			dispatch({
				type: 'DASHBOARD_LISTADO',
				payload: resp
			});
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaCliente() {
	return async (dispatch, getState) => {
    	await axios.get(`${consts.API_URL}/cliente/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'DASHBOARD_CLIENTE_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}