import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	},
	lista: [],
	listaCliente: [],
	listaFornecedor: [],
	listaPropriedade: [],
	listaEventoTipo: [],
	listaProduto: [],
	listaCentroDistribuicao: [],
	listaEventos: [],
	modoTelaEvento: 'lista'
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'VIAGEM_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'VIAGEM_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'VIAGEM_FILTRO':
            return { ...state,
				filtro: action.payload.filtro
			};

        case 'VIAGEM_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'VIAGEM_CLIENTE_SELECT_LISTADO':
            return {
				...state,
				listaCliente: action.payload.data
			};

		case 'VIAGEM_FORNECEDOR_SELECT_LISTADO':
            return {
				...state,
				listaFornecedor: action.payload.data
			};

		case 'VIAGEM_PROPRIEDADE_SELECT_LISTADO':
            return {
				...state,
				listaPropriedade: action.payload.data
			};

		case 'VIAGEM_EVENTO_TIPO_SELECT_LISTADO':
            return {
				...state,
				listaEventoTipo: action.payload.data
			};

		case 'VIAGEM_PRODUTO_SELECT_LISTADO':
            return {
				...state,
				listaProduto: action.payload.data
			};

		case 'VIAGEM_CENTRO_DISTRIBUICAO_SELECT_LISTADO':
            return {
				...state,
				listaCentroDistribuicao: action.payload.data
			};

		case 'VIAGEM_EVENTO_MODO_TELA':
			return { ...state,
				modoTelaEvento: action.payload.modo
			};

        case 'VIAGEM_EVENTO_LISTADO':
			return {
				...state,
				listaEventos: action.payload.data
			};

        default:
            return state;
    }
}
