import React from 'react'

export default props => (
    <td {...props}
		align={props.alignRight ? 'right' : (props.alignCenter ? 'center' : 'left')}
		onClick={props.onClick}
		title={props.small ? props.children : null}
		style={{
			paddingLeft: 2,
			paddingTop: 2,
			paddingRight: 8,
			paddingBottom: 2,
			verticalAlign: 'middle',
			minWidth: props.minWidth,
			color: props.color,
			backgroundColor: props.backgroundColor,
			maxWidth: props.noWrap ? 0 : null,
			overflow: props.noWrap ? 'hidden' : null,
			textOverflow: props.noWrap ? 'ellipsis' : null,
			whiteSpace: props.noWrap ? 'nowrap' : null,
			fontSize: props.small ? 11 : null,
			fontWeight: props.bold ? 'bold' : null
		}}>
		{props.children}
	</td>
)
