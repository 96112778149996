import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'VIAGEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'VIAGEM_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro = {}) {
    return {
        type: 'VIAGEM_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('viagemForm', registro)
    ];
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/viagem?
			data_inicial=${DateFormat.formatarDataTelaParaSql(getState().viagem.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().viagem.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().viagem.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().viagem.filtro.data_final)}`)
		.then(resp => {
			dispatch({
		        type: 'VIAGEM_LISTADO',
		        payload: resp
		    });
			console.log(resp);
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {

			dispatch(setAguardando(true));

			registro = {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_carregamento: registro.datahora_carregamento ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_carregamento) : null
			};

	        axios.post(`${consts.API_URL}/viagem`, registro).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/viagem`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_carregamento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_carregamento)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function finalizar(registro) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/viagem/finalizar`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id,
			datahora_finalizacao: DateFormat.getDataHoraAtual()
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function reabrir(registro) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/viagem/finalizar`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id,
			datahora_finalizacao: null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/viagem?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaCliente() {
	return async (dispatch, getState) => {
    	await axios.get(`${consts.API_URL}/cliente/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'VIAGEM_CLIENTE_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaFornecedor() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/fornecedor/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'VIAGEM_FORNECEDOR_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaPropriedade() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/paletePropriedade/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'VIAGEM_PROPRIEDADE_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaEventoTipo() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/viagemEventoTipo/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'VIAGEM_EVENTO_TIPO_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect`);
    return {
        type: 'VIAGEM_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCentroDistribuicao() {
    const request = axios.get(`${consts.API_URL}/centroDistribuicao/listarSelect`);
    return {
        type: 'VIAGEM_CENTRO_DISTRIBUICAO_SELECT_LISTADO',
        payload: request
    };
}

//Eventos

export function setModoTelaEvento(modo, registro = {}) {
    return {
        type: 'VIAGEM_EVENTO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initFormEvento(registro = {}) {
    return [
        initialize('viagemEventoForm', registro)
    ];
}

export function getListaEventos(id_viagem) {
	return (dispatch, getState) => {

		dispatch({
			type: 'VIAGEM_EVENTO_LISTADO',
			payload: { data: [] }
		});

		axios.get(`${consts.API_URL}/viagemEvento?id_viagem=${id_viagem || 0}`)
		.then(resp => {
			dispatch({
				type: 'VIAGEM_EVENTO_LISTADO',
				payload: resp
			});
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function setListaEvento(lista) {
	return {
		type: 'VIAGEM_EVENTO_LISTADO',
		payload: { data: lista }
	};
}

export function salvarEvento(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/viagemEvento`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_evento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_evento)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaEventos(registro.id_viagem));
				dispatch(setModoTelaEvento('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/viagemEvento`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_evento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_evento)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaEventos(registro.id_viagem));
				dispatch(setModoTelaEvento('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirEvento(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/viagemEvento?id=${registro.id}&id_viagem=${registro.id_viagem}&id_usuario_alteracao=${registro.id_usuario_alteracao}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaEventos(registro.id_viagem));
			dispatch(setModoTelaEvento('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };

}

export function buscaInformacaoSolicitacao(registro) {
	return async (dispatch, getState) => {

		if (!registro.solicitacao || !registro.solicitacao.trim()) {
			setErroAPI('Solicitação não informada');
			return;
		}

		await axios.get(`${consts.API_URL}/viagem/buscaInformacaoSolicitacao?solicitacao=${(registro.solicitacao || '').trim()}`)
		.then(async resp => {
			if (resp.data) {

				let clienteOrigem = getState().viagem.listaCliente.filter(item => item.cnpj == resp.data.cnpj_cliente_origem)[0];
				let novoCliente = false;
				if (!clienteOrigem) {
					novoCliente = true;
					await axios.post(`${consts.API_URL}/cliente`, {
						razao_social: resp.data.nome_cliente_origem,
						nome_fantasia: resp.data.nome_cliente_origem,
						cnpj: resp.data.cnpj_cliente_origem,
						id_usuario_alteracao: getState().auth.usuario.id
					}).then(async resp => {
						clienteOrigem = {
							...resp.data,
							razao_social: resp.data.nome_cliente_origem,
							nome_fantasia: resp.data.nome_cliente_origem,
							cnpj: resp.data.cnpj_cliente_origem,
							id_usuario_alteracao: getState().auth.usuario.id
						};
					}).catch(e => {
						setErroAPI(e);
					});
				}

				let clienteDestino = getState().viagem.listaCliente.filter(item => item.cnpj == resp.data.cnpj_cliente_destino)[0];
				if (!clienteDestino) {
					novoCliente = true;
					await axios.post(`${consts.API_URL}/cliente`, {
						razao_social: resp.data.nome_cliente_destino,
						nome_fantasia: resp.data.nome_cliente_destino,
						cnpj: resp.data.cnpj_cliente_destino,
						id_usuario_alteracao: getState().auth.usuario.id
					}).then(async resp => {
						clienteDestino = {
							...resp.data,
							razao_social: resp.data.nome_cliente_destino,
							nome_fantasia: resp.data.nome_cliente_destino,
							cnpj: resp.data.cnpj_cliente_destino,
							id_usuario_alteracao: getState().auth.usuario.id
						};
					}).catch(e => {
						setErroAPI(e);
					});
				}

				if (novoCliente) {
					dispatch(await getListaCliente());
				}

				dispatch(initForm({
					...registro,
					datahora_carregamento: DateFormat.formatarDataHoraSqlParaTela(resp.data.datahora_carga),
					id_cliente_origem: clienteOrigem ? clienteOrigem.id : null,
					id_cliente_destino: clienteDestino ? clienteDestino.id : null,
					cte_origem: resp.data.cte,
					motorista: resp.data.nome_motorista,
					carreta: resp.data.carretas,
					cavalo: resp.data.cavalo
				}));
			}
		}).catch(e => {
			console.log(e);
			// setErroAPI(e);
		});
	}
}
