import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import ClienteReducer from '../cliente/clienteReducer';
import CentroDistribuicaoReducer from '../centroDistribuicao/centroDistribuicaoReducer';
import FornecedorReducer from '../fornecedor/fornecedorReducer';
import ProdutoReducer from '../produto/produtoReducer';
import ViagemReducer from '../viagem/viagemReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    usuario: UsuarioReducer,
	cliente: ClienteReducer,
	fornecedor: FornecedorReducer,
	centroDistribuicao: CentroDistribuicaoReducer,
	produto: ProdutoReducer,
	viagem: ViagemReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
